import { MSAToolApi } from "@msa-tool/api";
import { getEidQuery } from "@msa-tool/eid";
import { triggerEvent, errorHandler } from "@host";

import { getAsyncActionCreator } from "../creators";
import { rootSelectors } from "../selectors";

import type { CreditQueryInput } from "@graphql/schema";

const asyncCreator = getAsyncActionCreator(rootSelectors);

export const equifaxActions = asyncCreator({
    async getMSAAuth(_: null, models) {
        const { user, msaTool, dealer } = models;
        const input = rootSelectors.getAuthPayload(null, models);

        const apiInput = {
            input,
            widgetId: dealer.state.widgetId,
        };

        const response = await MSAToolApi.getAuth(apiInput);

        if (response && response.creditAuth) {
            if (!response.creditAuth?.consumer) {
                throw new errorHandler.CriticalError("Invalid response from credit auth\nNo consumer id returned!", {
                    input,
                });
            }
            triggerEvent({
                event: "CreditToolSubmit",
            });
            user.update.leadId(response.creditAuth.consumer);
            msaTool.update.query(response.creditAuth);
        } else {
            throw new errorHandler.CriticalError(
                "Invalid response from creditAuth",
                { apiInput, response },
            );
        }

        if (response.creditAuth?.report) {
            msaTool.update.report(response.creditAuth!.report);
            triggerEvent({
                event: "CreditReportReceived",
            });
            return true;
        }

        return getEidQuery(response.creditAuth);
    },

    async getMSAEidAnswer(
        input: Omit<CreditQueryInput, "consumer">,
        models,
    ) {
        const { msaTool, user } = models;

        const response = await MSAToolApi.getAnswer({
            input: {
                ...input,
                consumer: user.state.leadId,
            },
        });

        if (!response || !response.creditQuery) {
            throw "Invalid response from answer query!";
        }

        if (response.creditQuery?.report) {
            msaTool.update.report(response.creditQuery!.report);
            triggerEvent({
                event: "CreditReportReceived",
            });
            return true;
        }

        msaTool.update.query(response.creditQuery);

        return getEidQuery(response.creditQuery);
    },
});