import { frameHandler } from "@host";
import {
    isCLC,
    isCarDoor,
    isOntarioAutoLoans,
    isGoodFellows,
    isCGOpenRoadOutlet,
} from "./customers";

const REFINANCE_DOMAIN = ["https://refi.carloanscanada.com"].includes(frameHandler.domain);
export const BREEZE_DOMAINS = [
    "https://throttlepowersports.com",
    "https://breezefinancialsolutions.com",
    "https://breezepowersportsfinancing.com",
].includes(frameHandler.domain);

const usePhoneVerify = isCLC || isCarDoor || isOntarioAutoLoans || isGoodFellows;

const DEFAULT_STEPS = [
    "vehicle",
    "date-of-birth",
    "employment",
    "income",
    "employment-duration",
    "budget",
    "employer",
    "address",
    "address-duration",
    "name",
    "email",
    "phone",
    usePhoneVerify ? "phone-verify" : "",
    "eid",
].filter(Boolean);

const BREEZE_STEPS = [
    "vehicle",
    "name",
    "email",
    "date-of-birth",
    "address",
    "phone",
    "eid",
].filter(Boolean);

const REFINANCE_STEPS = [
    "vehicle-trade-in",
    "interest-rate",
    "date-of-birth",
    "employment",
    "income",
    "employment-duration",
    "budget",
    "employer",
    "address",
    "address-duration",
    "name",
    "email",
    "phone",
    usePhoneVerify ? "phone-verify" : "",
    "eid",
].filter(Boolean);

const CG_OPEN_ROAD_OUTLET_STEPS = [
    "vehicle",
    "employment",
    "income",
    "employment-duration",
    "budget",
    "employer",
    "address",
    "address-duration",
    "name",
    "date-of-birth",
    "email",
    "phone",
    usePhoneVerify ? "phone-verify" : "",
    "eid",
].filter(Boolean);

// HELPER TO GENERATE MANY STEPS
export const order = (
    BREEZE_DOMAINS
        ? BREEZE_STEPS
        : REFINANCE_DOMAIN
            ? REFINANCE_STEPS
            : isCGOpenRoadOutlet
                ? CG_OPEN_ROAD_OUTLET_STEPS
                : DEFAULT_STEPS
);

export const MAX_QUESTIONS = order.length;

export const getStepNumber = (search: string) => {
    return order.findIndex((v) => search === v) + 1;
};

export const steps = order.reduce((agg, step, i) => ({
    ...agg,
    [step]: { next: order[i + 1], back: !!i },
}), {});