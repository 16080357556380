import { BREEZE_DOMAINS } from "../steps";
import { isEnergyPowersports, isCGOpenRoadOutlet } from "../customers";
import CarImg from "@assets/car.png";
import TruckImg from "@assets/truck.png";
import SuvImg from "@assets/suv.png";
import MinivanImg from "@assets/minivan.png";
import SledImg from "@assets/sled.png";
import GolfImg from "@assets/golf.png";
import RvImg from "@assets/rv.png";
import AtvImg from "@assets/atv.png";
import MotorcycleImg from "@assets/motorcycle.png";
import PowersportsImg from "@assets/powersports.png";
import OtherVehicleImg from "@assets/other.png";
import ClassA from "@assets/rv-expanded-class-a.png";
import ClassB from "@assets/rv-expanded-class-b.png";
import ClassC from "@assets/rv-expanded-class-c.png";
import FifthWheel from "@assets/rv-expanded-fifth-wheel.png";
import MicroTrailer from "@assets/rv-expanded-micro-trailer.png";
import TentTrailer from "@assets/rv-expanded-tent-trailer.png";
import TravelTrailer from "@assets/rv-expanded-travel-trailer.png";
import EnergyPowersportsMotorcycle from "@assets/energy-powersports-motorcycle.png";
import EnergyPowersportsAtv from "@assets/energy-powersports-atv.png";
import EnergyPowersportsSkidoo from "@assets/energy-powersports-skidoo.png";
import EnergyPowersportsSeadoo from "@assets/energy-powersports-seadoo.png";
import CgOpenRoadOutletPowersports from "@assets/cg-open-road-outlet-double-atv.png";
import CgOpenRoadOutletCars from "@assets/cg-open-road-outlet-double-auto.png";
import CgOpenRoadOutletMarine from "@assets/cg-open-road-outlet-double-marine.png";
import CgOpenRoadOutletRv from "@assets/cg-open-road-outlet-double-rv.png";

const DEFAULT_VEHICLE_ANSWERS = [
    {
        key: "car",
        value: "Car",
        image: CarImg,
    },
    {
        key: "truck",
        value: "Truck",
        image: TruckImg,
    },
    {
        key: "suv",
        value: "SUV",
        image: SuvImg,
    },
    {
        key: "mini-van",
        value: "Mini Van",
        image: MinivanImg,
    },
];

const DEFAULT_POWERSPORTS_CONFIG = {
    question: "What type of product are you looking for?",
    answers: [
        {
            key: "sled",
            value: "Sled",
            image: SledImg,
        },
        {
            key: "atv",
            value: "ATV / Side by Side",
            image: AtvImg,
        },
        {
            key: "motorcycle",
            value: "Motorcycle",
            image: MotorcycleImg,
        },
        {
            key: "other",
            value: "Other",
            image: OtherVehicleImg,
        },
    ],
};

const BREEZE_VEHICLE_ANSWERS = [
    {
        key: "atv",
        value: "ATV",
    },
    {
        key: "watercraft",
        value: "Watercraft",
    },
    {
        key: "snowmobile",
        value: "Snowmobile",
    },
    {
        key: "trailer",
        value: "Trailer",
    },
];

const ENERGY_POWERSPORTS_CONFIG = {
    question: "What type of product are you looking for?",
    answers: [
        {
            key: "atv",
            value: "ATV / Side by Side",
            image: EnergyPowersportsAtv,
        },
        {
            key: "boat",
            value: "Boat / PWC",
            image: EnergyPowersportsSeadoo,
        },
        {
            key: "snowmobile",
            value: "Snowmobile",
            image: EnergyPowersportsSkidoo,
        },
        {
            key: "motorcycle",
            value: "Motorcycle",
            image: EnergyPowersportsMotorcycle,
        },
    ],
};

const CG_OPEN_ROAD_OUTLET_CONFIG = {
    question: "What are you looking to buy?",
    answers: [
        {
            key: "powersports",
            value: "Powersports",
            image: CgOpenRoadOutletPowersports,
        },
        {
            key: "rv",
            value: "RV",
            image: CgOpenRoadOutletRv,
        },
        {
            key: "marine",
            value: "Marine",
            image: CgOpenRoadOutletMarine,
        },
        {
            key: "auto",
            value: "Auto",
            image: CgOpenRoadOutletCars,
        },
    ],
};

const automotive = {
    question: "What type of vehicle are you looking for?",
    answers: BREEZE_DOMAINS ? BREEZE_VEHICLE_ANSWERS : DEFAULT_VEHICLE_ANSWERS,
};

export const getDynamicConfig = (type: string) => {
    if (!type?.toLowerCase) {
        return automotive;
    }
    switch (type.toLowerCase()) {
        case "powersports":
            return isEnergyPowersports
                ? ENERGY_POWERSPORTS_CONFIG
                : isCGOpenRoadOutlet
                    ? CG_OPEN_ROAD_OUTLET_CONFIG
                    : DEFAULT_POWERSPORTS_CONFIG;
        case "powersports-2":
            return {
                question: "What type of product are you looking for?",
                answers: [
                    {
                        key: "powersports",
                        value: "Powersports",
                        image: PowersportsImg,
                    },
                    {
                        key: "rv/trailer",
                        value: "RV / Trailer",
                        image: RvImg,
                    },
                    {
                        key: "marine",
                        value: "Marine",
                        image: OtherVehicleImg,
                    },
                    {
                        key: "other",
                        value: "Other",
                        image: GolfImg,
                    },
                ],
            };
        case "powersports-breeze":
            return {
                question: "What type of vehicle are you looking for?",
                answers: [
                    {
                        key: "atv",
                        value: "ATV / Side by Side",
                    },
                    {
                        key: "watercraft",
                        value: "Watercraft",
                    },
                    {
                        key: "snowmobile",
                        value: "Snowmobile",
                    },
                    {
                        key: "trailer",
                        value: "Trailer",
                    },
                    {
                        key: "dirt-bike",
                        value: "Dirt Bike",
                    },
                ],
            };
        case "rv":
            return {
                question: "What type of product are you looking for?",
                answers: [
                    {
                        key: "rv",
                        value: "RV",
                    },
                    {
                        key: "5th-wheel",
                        value: "5th Wheel",
                    },
                    {
                        key: "trailer",
                        value: "Trailer",
                    },
                    {
                        key: "other",
                        value: "Other",
                    },
                ],
            };
        case "rv-expanded":
            return {
                question: "What type of product are you looking for?",
                answers: [
                    {
                        key: "class-a",
                        value: "Class A",
                        image: ClassA,
                    },
                    {
                        key: "class-b",
                        value: "Class B",
                        image: ClassB,
                    },
                    {
                        key: "class-c",
                        value: "Class C",
                        image: ClassC,
                    },
                    {
                        key: "fifth-wheel",
                        value: "Fifth Wheel",
                        image: FifthWheel,
                    },
                    {
                        key: "travel-trailer",
                        value: "Travel Trailer",
                        image: TravelTrailer,
                    },
                    {
                        key: "micro-trailer",
                        value: "Micro Trailer",
                        image: MicroTrailer,
                    },
                    {
                        key: "tent-trailer",
                        value: "Tent Trailer",
                        image: TentTrailer,
                    },
                ],
            };
        case "default":
        default:
            return automotive;
    }
};