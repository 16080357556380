import { gql } from "@api/api-client";

const CreditReportFragment = gql`
    fragment CreditReport on CreditReport {
        score {
            value
        }
        trades {
            creditor {
                name
            }
            dateOpened
            balanceAmount
            paymentTermAmount
            association
            portfolioType {
                code
                description
            }
            paymentRate {
                code
                description
            }
            highCreditAmount
            dateLastActivityOrPayment
            monthsReviewed
            paymentTerm
            lastSlowPayment
            derogatoryCounters {
                past30
                past60
                past90
            }
        }
        inquiries {
            date
            inquirer {
                id
                name
                phone
            }
        }
    }
`;

const CreditResponseFragment = gql`
    fragment CreditQuery on CreditQuery {
        transactionKey
        equifaxServerError
        equifaxErrorMessages
        fraudCheckFailed
        assessmentComplete {
            passed
            reasons {
                value
                description
            }
        }
        interactiveQuery {
            queryId
            questions {
                questionId
                questionText
                answers {
                    answerId
                    value
                }
            }
        }
        report {
            ...CreditReport
        }
    }
    ${CreditReportFragment}
`;

export const GetCreditAuth = gql`
    mutation auth($widgetId: String!, $input: CreditAuthInput!) {
        creditAuth(widgetId: $widgetId, input: $input) {
            ...CreditQuery
            consumer
        }
    }
    ${CreditResponseFragment}
`;

export const GetCreditAnswer = gql`
    mutation answer($input: CreditQueryInput!) {
        creditQuery(input: $input) {
            ...CreditQuery
        }
    }
    ${CreditResponseFragment}
`;